
<script setup>
    import MFilterFields from "mobile.vue.components.filter.FilterFields.vue";
    import MFilterOrders from "mobile.vue.components.filter.FilterOrders.vue";
</script>

<template>
    <m-filter-fields v-bind="$attrs" />
    <m-filter-orders v-bind="$attrs" />
</template>
